<template>
  <vs-card style="margin-bottom: 2%">
    <div slot="header">
      <vs-row style="margin-bottom: 2%">
        <vs-col
          style="font-size: 14px"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="9"
        >
          <b v-if="userdata.mwb_id !== null ">{{ userdata.person_name }}</b>
          <b v-if="userdata.mwb_id === null ">{{ userdata.phone_number }}</b>
          <!-- <img v-if="userdata.data.direction === 'inbound'"
            style="margin-left: 3%"
            :src="getcalltypeicon('INCOMING')"
          />
           <img v-else
            style="margin-left: 3%"
            :src="getcalltypeicon('OUTGOING')"
            v-bind:alt="userdata.data.type"
          /> -->
          <vs-chip color="danger" v-if="userdata.count > 1">{{
            userdata.count
          }}</vs-chip>
        </vs-col>
        <vs-col
          style="font-size: 12px"
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-w="3"
        >
          <vs-button
            v-if="userdata.mwb_id == null"
            style="margin-left: 2%"
            color="warning"
            type="gradient"
            icon="edit"
            @click="assignMissed"
          ></vs-button>
          <vs-button v-if="userdata.mwb_id !== null "
            color="warning"
            type="gradient"
            @click="openPopUp"
            icon="info"
          ></vs-button>
        </vs-col>
      </vs-row>
    </div>
    <vs-row style="margin-bottom: 2%">
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="left"
        vs-align="flex-start"
        vs-w="3"
        >Duration</vs-col
      >
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="left"
        vs-align="flex-start"
        vs-w="1"
        >:</vs-col
      >
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="9"
        >{{ secondsToHms(userdata.outbound_sec) }}</vs-col
      >
    </vs-row>
    <vs-row style="margin-bottom: 2%">
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="3"
        >Time</vs-col
      >
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="1"
        >:</vs-col
      >
      <vs-col
        style="font-size: 13px"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="left"
        vs-w="8"
        >{{ timeFormat(userdata.start_stamp) }}</vs-col
      >
    </vs-row>
  </vs-card>
</template>

<script>
// import EventBus from "../eventbus.js";
import moment from "moment";
import EventBus from "../eventbus.js";
// import axios from "axios";
// import constants from "../../../constants.json";
export default {
  props: {
    userdata: {}
  },
  methods: {
    async openPopUp() {
      this.openBigPopup(this.userdata.mwb_id);
      console.log("my data: ", this.userdata);
      // if (this.userdata.data.level == "M7" && this.userdata.data.city == "Hyderabad") {
      //   if (this.userdata.data.courses.indexOf("CPA") != -1) {
      //     this.openCPAPopup(this.userdata.data);
      //     return;
      //   } else if (this.userdata.data.courses.indexOf("CMA") != -1) {
      //     this.openCMAPopup(this.userdata.data);
      //     return;
      //   }
      // }
      // this.openMWBPopup(this.userdata.data);
    },
    getcalltypeicon(type) {
      //"inbound" outbound
      if(type === 'inbound'){
        type = 'INCOMING';
        return type
      }else if(type === 'outbound'){
        type = 'OUTGOING';
        return type
      }

      switch (type) {
        case "OUTGOING":
          return "https://img.icons8.com/color/20/000000/outgoing-call.png";
        case "INCOMING":
          return "https://img.icons8.com/color/20/000000/callback.png";
        case "MISSED":
          return "https://img.icons8.com/office/20/000000/missed-call.png";
        case "REJECTED":
          return "https://img.icons8.com/color/20/000000/call-disconnected.png";
      }
    },
    getupdateicon(type) {
      switch (type) {
        case 1:
          return "https://img.icons8.com/color/20/000000/ok.png";
        case 0:
          return "https://img.icons8.com/color/22/000000/error.png";
      }
    },
    timeFormat(time){
      let dateAndTime = moment.unix(time).format('MMMM Do YYYY, h:mm:ss a');
      return dateAndTime;
    },
    secondsToHms(d) {
      d = Number(d);

      let h = Math.floor(d / 3600);
      let m = Math.floor((d % 3600) / 60);
      let s = Math.floor((d % 3600) % 60);

      return (
        ("0" + h).slice(-2) +
        ":" +
        ("0" + m).slice(-2) +
        ":" +
        ("0" + s).slice(-2)
      );
    },
    unixTimestampConvert(value) {
      value = value / 1000;
      var dateString = moment.unix(value).format("DD-MMMM-YY h:mm");
      switch (dateString) {
        case "01/01/1970":
          return "";
        default:
          return dateString;
      }
    },
     assignMissed() {
      EventBus.$emit("open-untracked-details-popup", this.userdata);
    },
  }
};
</script>

<style></style>
