<template>
  <div>
    <vs-row type="flex" style="margin-top: 2%">
      <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="8">
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="4"
        style="margin-bottom: 1%"
      >
        <v-select
          placeholder="Levels"
          class="w-full"
          v-model="LevelsSelect"
          :options="levelsOptions"
        ></v-select>
      </vs-col>

      <!-- <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" style="margin-bottom: 1%;">
        <vs-button
          class="rounded-r-none p-3 md:px-8 md:py-3"
          @click="m7Team('M7')"
          :color="colorx"
          :style="m7teamselectbuttoncolor"
          size="small"
          type="border"
          >M7</vs-button
        >
        <vs-button
          class="rounded-l-none p-3 md:px-8 md:py-3"
          @click="nonm7Team('non_m7')"
          :color="colorx"
          :style="nonm7teamselectbuttoncolor"
          size="small"
          type="border"
          >Non-M7</vs-button
        >
      </vs-col> -->
    </vs-row>
    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base">
        <vs-tabs v-model="activeTabcallLInk">
          <vs-tab
            :id="'MissedcallsTab'"
            :label="'Missed calls [0]'"
            @click="changepagination2('Missed calls')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div
                v-for="(data, index) in this.$store.state.MissedCalls"
                :key="index"
                :id="'missed_calls' + index"
              >
                <missed-calls :userdata="data" />
                <!-- <UntrackedMissedCalls v-show=""/> -->
              </div>
            </div>
          </vs-tab>
          <vs-tab
            :id="'UntrackedTab'"
            label="Untracked Calls [0]"
            @click="changepagination2('Untracked')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div
                v-for="(data, index) in this.$store.state.UnTracked"
                :key="index"
                :id="'untracked_calls' + index"
              >
                <untracked-calls :data="data" />
              </div>
            </div>
          </vs-tab>
          <vs-tab
            :id="'ToupdateTab'"
            label="To Update [0]"
            @click="changepagination2('ToUpdate')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div
                v-for="(data, index) in this.$store.state.ToUpdate"
                :key="index"
                :id="'to_update' + index"
              >
                <to-update :userdata="data" />
              </div>
            </div>
          </vs-tab>
          <vs-tab
            id="CalllogsTab"
            label="Call Logs [0]"
            @click="changepagination2('Calllog')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div
                v-for="(data, index) in this.$store.state.CallLogs"
                :key="index"
              >
                <call-log :userdata="data" />
              </div>
            </div>
          </vs-tab>
          <vs-tab
            id="IVRAllMissedCallsTab"
            label="IVR Missed/Untracked Calls [0]"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div v-for="(data, index) in ivrAllMissedCallsdata" :key="index">
                <!-- <IvrMissedCalls :userdata="data"  v-if="renderComponent" /> -->
                <IvrMissedCalls :userdata="data" />
              </div>
              <!-- <IvrMissedCalls :userdata="ivrAllMissedCallsdata" /> -->
            </div>
          </vs-tab>
          <vs-tab id="IVRTodayCallLogsTab" label="IVR Today Call Logs [0]">
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <!-- <h6>IVR Today Call Logs No Data </h6> -->
              <div v-for="(data, index) in ivrTodayCallLogsdata" :key="index">
                <IvrCallLogs :userdata="data" />
              </div>
            </div>
          </vs-tab>
        </vs-tabs>
        <vs-pagination
          v-if="updatepagination === true"
          :total="updatePtotal"
          v-model="updatePvalue"
        ></vs-pagination>
        <vs-pagination
          v-if="missedcallpagination"
          :total="missedcallPtotal"
          v-model="missedcallPvalue"
        ></vs-pagination>
        <vs-pagination
          v-if="untrackedpagination === true"
          :total="untrackedPtotal"
          v-model="untrackedPvalue"
        ></vs-pagination>
        <vs-pagination
          v-if="calllogpagination === true"
          :total="calllogPtotal"
          v-model="calllogPvalue"
        ></vs-pagination>
      </div>
      <div
        class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base"
        v-show="allTabslevels"
      >
        <vs-tabs v-model="activeTabLInk">
          <vs-tab
            id="FlagTab"
            :label="'Flag [0]'"
            @click="changepagination('Flag')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div v-for="(data, index) in flagSection" :key="index">
                <worklog :data="data" />
              </div>
            </div>
          </vs-tab>
          <vs-tab
            id="EscalationsTab"
            :label="'Escalation [0]'"
            @click="changepagination('Escalations')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div v-for="(data, index) in escalations" :key="index">
                <DashboardEscalations :data="data"></DashboardEscalations>
                <!-- <NetEnquiries
                v-if="data.net_enquiry_type !== 'chat'"
                :userdata="data"
              />
              <NetEnquiriesChat v-else :userdata="data" /> -->
              </div>
            </div>
          </vs-tab>
          <vs-tab
            id="mhptab"
            :label="'MHP [0]'"
            @click="changepagination('mhp')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div v-for="(data, index) in this.$store.state.MHP" :key="index">
                <!-- <Untapped :data="data" /> -->
                <MHP :data="data"></MHP>
              </div>
            </div>
          </vs-tab>
          <!--Referrals-->
          <vs-tab
            id="referralstab"
            :label="'Referrals [0]'"
            @click="changepagination('Referral')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div
                v-for="(data, index) in this.$store.state.Referrals"
                :key="index"
              >
                <DashboardReferrals :data="data"></DashboardReferrals>
              </div>
            </div>
          </vs-tab>
          <!-- <vs-tab
            id="untappedTab"
            :label="'Untapped [0]'"
            @click="changepagination('Untapped')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div
                v-for="(data, index) in this.$store.state.UnTapped"
                :key="index"
              >
                <Untapped :data="data" />
              </div>
            </div>
          </vs-tab> -->
          <vs-tab
            id="delaysTab"
            :label="'Delays [0]'"
            @click="changepagination('Delays')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div
                v-for="(data, index) in this.$store.state.Delays"
                :key="index"
              >
                <worklog :data="data" />
              </div>
            </div>
          </vs-tab>
          <vs-tab
            id="todayTab"
            :label="'Today [0]'"
            @click="changepagination('Today')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div
                v-for="(data, index) in this.$store.state.Today"
                :key="index"
              >
                <worklog :data="data" />
              </div>
            </div>
          </vs-tab>
          <vs-tab
            id="waitingTab"
            :label="'Waiting [0]'"
            @click="changepagination('Waiting')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div
                v-for="(data, index) in this.$store.state.Waiting"
                :key="index"
              >
                <worklog :data="data" />
              </div>
            </div>
          </vs-tab>
          <vs-tab v-if="ne_data_allowed"
            id="NetEnquiriesTab"
            :label="'Net Enquiries [0]'"
            @click="changepagination('NetEnquiries')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div
                v-for="(data, index) in this.netEnquiriesData"
                :key="index"
              >
                <NetEnquiries :userdata="data" />
                <!-- <NetEnquiriesChat v-else :userdata="data" /> -->
              </div>
            </div>
          </vs-tab>
        </vs-tabs>
        <vs-pagination
          v-if="netenquiriesPagination === true"
          :total="netEnquiriesPtotal"
          v-model="netEnquiriesPvalue"
        ></vs-pagination>
        <vs-pagination
          v-if="missed_hot_pocketpagination === true"
          :total="missed_hot_pocketPtotal"
          v-model="missed_hot_pocketPvalue"
        ></vs-pagination>
        <vs-pagination
          v-if="referralpagination === true"
          :total="referralPtotal"
          v-model="referralPvalue"
        ></vs-pagination>
        <vs-pagination
          v-if="delayspagination === true"
          :total="delayPtotal"
          v-model="delayPvalue"
        ></vs-pagination>
        <vs-pagination
          v-if="todayspagination === true"
          :total="todayPtotal"
          v-model="todayPvalue"
        ></vs-pagination>
        <vs-pagination
          v-if="waitingpagination === true"
          :total="waitingPtotal"
          v-model="waitingPvalue"
        ></vs-pagination>
        <vs-pagination
          v-if="untappedpagination === true"
          :total="untappedPtotal"
          v-model="untappedPvalue"
        ></vs-pagination>
        <vs-pagination
          v-if="filteredpagination === true"
          :total="filteredPtotal"
          v-model="filteredPvalue"
        ></vs-pagination>
        <vs-pagination
          v-if="flagpagination === true"
          :total="flagPtotal"
          v-model="flagPvalue"
        ></vs-pagination>
      </div>
      <div
        class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base"
        v-show="nonM7abovelevels"
      >
        <vs-tabs v-model="nonM7activeTabLInk">
          <vs-tab
            id="nonM7FlagTab"
            :label="'Flag [0]'"
            @click="changepagination('Flag')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div v-for="(data, index) in flagSection" :key="index">
                <worklog :data="data" />
              </div>
            </div>
          </vs-tab>
          <vs-tab
            id="nonM7EscalationsTab"
            :label="'Escalation [0]'"
            @click="changepagination('Escalations')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div v-for="(data, index) in escalations" :key="index">
                <DashboardEscalations :data="data"></DashboardEscalations>
              </div>
            </div>
          </vs-tab>
          <vs-tab
            id="nonM7mhptab"
            :label="'MHP [0]'"
            @click="changepagination('mhp')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div v-for="(data, index) in this.$store.state.MHP" :key="index">
                <!-- <Untapped :data="data" /> -->
                <MHP :data="data"></MHP>
              </div>
            </div>
          </vs-tab>
          <!--Referrals-->
          <vs-tab
            id="nonM7referralstab"
            :label="'Referrals [0]'"
            @click="changepagination('Referral')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div
                v-for="(data, index) in this.$store.state.Referrals"
                :key="index"
              >
                <DashboardReferrals :data="data"></DashboardReferrals>
              </div>
            </div>
          </vs-tab>
          <!-- <vs-tab
            id="nonM7untappedTab"
            :label="'Untapped [0]'"
            @click="changepagination('Untapped')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div
                v-for="(data, index) in this.$store.state.UnTapped"
                :key="index"
              >
                <Untapped :data="data" />
              </div>
            </div>
          </vs-tab> -->
          <vs-tab
            id="nonM7delaysTab"
            :label="'Delays [0]'"
            @click="changepagination('Delays')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div
                v-for="(data, index) in this.$store.state.Delays"
                :key="index"
              >
                <worklog :data="data" />
              </div>
            </div>
          </vs-tab>
          <vs-tab
            id="nonM7todayTab"
            :label="'Today [0]'"
            @click="changepagination('Today')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div
                v-for="(data, index) in this.$store.state.Today"
                :key="index"
              >
                <worklog :data="data" />
              </div>
            </div>
          </vs-tab>
          <vs-tab
            id="nonM7waitingTab"
            :label="'Waiting [0]'"
            @click="changepagination('Waiting')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div
                v-for="(data, index) in this.$store.state.Waiting"
                :key="index"
              >
                <worklog :data="data" />
              </div>
            </div>
          </vs-tab>
          <!-- <vs-tab v-show="allTabslevels"></vs-tab> -->
        </vs-tabs>
        <vs-pagination
          v-if="netenquiriesPagination === true"
          :total="netEnquiriesPtotal"
          v-model="netEnquiriesPvalue"
        ></vs-pagination>
        <vs-pagination
          v-if="missed_hot_pocketpagination === true"
          :total="missed_hot_pocketPtotal"
          v-model="missed_hot_pocketPvalue"
        ></vs-pagination>
        <vs-pagination
          v-if="referralpagination === true"
          :total="referralPtotal"
          v-model="referralPvalue"
        ></vs-pagination>
        <vs-pagination
          v-if="delayspagination === true"
          :total="delayPtotal"
          v-model="delayPvalue"
        ></vs-pagination>
        <vs-pagination
          v-if="todayspagination === true"
          :total="todayPtotal"
          v-model="todayPvalue"
        ></vs-pagination>
        <vs-pagination
          v-if="waitingpagination === true"
          :total="waitingPtotal"
          v-model="waitingPvalue"
        ></vs-pagination>
        <vs-pagination
          v-if="untappedpagination === true"
          :total="untappedPtotal"
          v-model="untappedPvalue"
        ></vs-pagination>
        <vs-pagination
          v-if="filteredpagination === true"
          :total="filteredPtotal"
          v-model="filteredPvalue"
        ></vs-pagination>
        <vs-pagination
          v-if="flagpagination === true"
          :total="flagPtotal"
          v-model="flagPvalue"
        ></vs-pagination>
      </div>
      <div
        class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base"
        v-show="M7abovelevels"
      >
        <vs-tabs v-model="M7activeTabLInk">
          <vs-tab
            id="M7EscalationsTab"
            :label="'Escalation [0]'"
            @click="changepagination('Escalations')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div v-for="(data, index) in escalations" :key="index">
                <DashboardEscalations :data="data"></DashboardEscalations>
              </div>
            </div>
          </vs-tab>
          <vs-tab
            id="M7mhptab"
            :label="'MHP [0]'"
            @click="changepagination('mhp')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div v-for="(data, index) in this.$store.state.MHP" :key="index">
                <!-- <Untapped :data="data" /> -->
                <MHP :data="data"></MHP>
              </div>
            </div>
          </vs-tab>
          <!--Referrals-->
          <vs-tab
            id="M7referralstab"
            :label="'Referrals [0]'"
            @click="changepagination('Referral')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div
                v-for="(data, index) in this.$store.state.Referrals"
                :key="index"
              >
                <!-- <Untapped :data="data" /> -->
                <DashboardReferrals :data="data"></DashboardReferrals>
              </div>
            </div>
          </vs-tab>
          <vs-tab
            id="M7delaysTab"
            :label="'Delays [0]'"
            @click="changepagination('Delays')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div
                v-for="(data, index) in this.$store.state.Delays"
                :key="index"
              >
                <worklog :data="data" />
              </div>
            </div>
          </vs-tab>
          <vs-tab
            id="M7todayTab"
            :label="'Today [0]'"
            @click="changepagination('Today')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div
                v-for="(data, index) in this.$store.state.Today"
                :key="index"
              >
                <worklog :data="data" />
              </div>
            </div>
          </vs-tab>
          <vs-tab
            id="M7waitingTab"
            :label="'Waiting [0]'"
            @click="changepagination('Waiting')"
          >
            <div class="tab-text" style="overflow-y: scroll; height: 600px">
              <div
                v-for="(data, index) in this.$store.state.Waiting"
                :key="index"
              >
                <worklog :data="data" />
              </div>
            </div>
          </vs-tab>
          <!-- <vs-tab v-show="allTabslevels"></vs-tab> -->
        </vs-tabs>
        <vs-pagination
          v-if="netenquiriesPagination === true"
          :total="netEnquiriesPtotal"
          v-model="netEnquiriesPvalue"
        ></vs-pagination>
        <vs-pagination
          v-if="missed_hot_pocketpagination === true"
          :total="missed_hot_pocketPtotal"
          v-model="missed_hot_pocketPvalue"
        ></vs-pagination>
        <vs-pagination
          v-if="referralpagination === true"
          :total="referralPtotal"
          v-model="referralPvalue"
        ></vs-pagination>
        <vs-pagination
          v-if="delayspagination === true"
          :total="delayPtotal"
          v-model="delayPvalue"
        ></vs-pagination>
        <vs-pagination
          v-if="todayspagination === true"
          :total="todayPtotal"
          v-model="todayPvalue"
        ></vs-pagination>
        <vs-pagination
          v-if="waitingpagination === true"
          :total="waitingPtotal"
          v-model="waitingPvalue"
        ></vs-pagination>
        <!-- <vs-pagination
                    v-if="untappedpagination === true"
                    :total="untappedPtotal"
                    v-model="untappedPvalue"
                ></vs-pagination> -->
        <vs-pagination
          v-if="filteredpagination === true"
          :total="filteredPtotal"
          v-model="filteredPvalue"
        ></vs-pagination>
        <!-- <vs-pagination
                    v-if="flagpagination === true"
                    :total="flagPtotal"
                    v-model="flagPvalue"
                ></vs-pagination> -->
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import ToUpdate from "../components/pagesComponents/DashBoardToUpdate.vue";
import MissedCalls from "../components/pagesComponents/DashboardMissed.vue";
import UntrackedCalls from "../components/pagesComponents/DashboardUntracked.vue";
import DashboardEscalations from "../components/pagesComponents/DashboardEscalations.vue";
import Worklog from "../components/pagesComponents/DashboardWorklog.vue";
import CallLog from "../components/pagesComponents/DashboardCalllog.vue";
import StatCards from "../components/pagesComponents/DashboardStatcards.vue";
// import Untapped from "../components/pagesComponents/DashboardUntapped.vue";
import MHP from "../components/pagesComponents/DashboardMHP.vue";
import NetEnquiries from "../components/pagesComponents/DashboardNetEnquiries.vue";
import NetEnquiriesChat from "../components/pagesComponents/DashboardNetEnquiriesChat.vue";
import DashboardReferrals from "../components/pagesComponents/DashboardReferrals.vue";
// import UntrackedMissedCalls from "../components/pagesComponents/DashboardMissedUntracked.vue";
import axios from "axios";
import EventBus from "../components/eventbus.js";
import constants from "../../constants.json";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import vSelect from "vue-select";
import IvrCallLogs from "../components/pagesComponents/IvrCallLogs";
import IvrMissedCalls from "../components/pagesComponents/IvrMissedCalls";
export default {
  mounted() {
    if(localStorage.getItem("spoc_id")){
      if(localStorage.getItem("spoc_id") == 712 || localStorage.getItem("spoc_id") == 2017){
        this.ne_data_allowed = true;
      }
    }else{
      setTimeout(() => {
        if(localStorage.getItem("spoc_id") == 712 || localStorage.getItem("spoc_id") == 2017){
          this.ne_data_allowed = true;
        }
      }, 2000);
    }
    EventBus.$off('update-dashboard');
    // if (localStorage.getItem("role") === "") {
    //   localStorage.setItem("role", "cm1");
    // } else if (localStorage.getItem("role") !== "cm1") {
    //   console.log("going to push");
    //   router.push("/adminDashboard");
    // }
    // EventBus.$emit("load-filter");
    // this.missedcallpagination = true;
    this.$store.commit("CLEAR_MWB_LEADS");
    // this.fetch_initital_logs();
    this.single_fetch_api();
    //   this.getNetEnquires();
    EventBus.$on("update-dashboard", (payload, payload1) => {
      console.log(payload, payload1);
      // this.fetch_initital_logs();
      console.log("acads dashboard emit listener ");
      // console.log(this.$route.path);
      if (this.$route.path === "/workDashboard") {
        this.single_fetch_api();
        //   this.getNetEnquires();
      }
    });
    EventBus.$on("update-dashboardNetEnquiries", () => {
      // this.levelsFilter = payload;
      // console.log("about to call");
      this.single_fetch_api();
      // this.getNetEnquires();
    });
    EventBus.$on("filter-dashboard", (payload) => {
      this.levelsFilter = payload;
      // console.log("about to call");
      this.single_fetch_api();
    });
  },
  beforeDestroy() {
    EventBus.$emit("unload-filter");
  },
  components: {
    Datepicker,
    ToUpdate,
    MissedCalls,
    UntrackedCalls,
    Worklog,
    CallLog,
    StatCards,
    // Untapped,
    Treeselect,
    DateRangePicker,
    NetEnquiries,
    NetEnquiriesChat,
    DashboardEscalations,
    MHP,
    DashboardReferrals,
    "v-select": vSelect,
    IvrMissedCalls,
    IvrCallLogs,
    // UntrackedMissedCalls
  },
  data() {
    return {
      spoc_id: '',
      ne_data_allowed: false,
      netEnquiriesData: '',
      ivrTodayCallLogsdata: [],
      ivrAllMissedCallsdata: [],
      allTabslevels: true,
      nonM7abovelevels: false,
      M7abovelevels: false,
      APItype: "",
      LevelsSelect: "All",
      levelsOptions: [
        "All",
        "Onboarding",
        "SR-M7",
        "Non M7",
        "SR-M8,M9,M10",
        // "SR-M9",
        // "SR-M10"
      ],
      team_select: this.team,
      colorx: "rgb(199, 42, 117)",
      selectedTeam: "M7",
      m7teamselectbuttoncolor: [
        {
          background: "rgb(199, 42, 117)",
          color: "#ffffff",
          border: "1px solid rgb(199, 42, 117)",
        },
      ],
      nonm7teamselectbuttoncolor: [
        {
          background: "#ffffff",
          color: "rgb(199, 42, 117)",
          border: "1px solid rgb(199, 42, 117)",
        },
      ],
      escalationText: "Escalations [0]",
      treeDataLevels: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "P1",
              label: "P1",
              children: [
                {
                  id: "M3+",
                  label: "M3+",
                },
                {
                  id: "M3",
                  label: "M3",
                },
                {
                  id: "M3++",
                  label: "M3++",
                },
                {
                  id: "M6",
                  label: "M6",
                },
                {
                  id: "M5",
                  label: "M5",
                },
              ],
            },
            {
              id: "P2",
              label: "P2",
              children: [
                {
                  id: "L2",
                  label: "L2",
                },
                {
                  id: "L3",
                  label: "L3",
                },
                {
                  id: "L4",
                  label: "L4",
                },
                {
                  id: "L6",
                  label: "L6",
                },
                {
                  id: "L5",
                  label: "L5",
                },
                {
                  id: "L3+",
                  label: "L3+",
                },
              ],
            },
            {
              id: "P3",
              label: "P3",
              children: [
                {
                  id: "L4-",
                  label: "L4-",
                },
                {
                  id: "L1",
                  label: "L1",
                },
              ],
            },
            {
              id: "P4",
              label: "P4",
              children: [
                {
                  id: "M4-",
                  label: "M4-",
                },
                {
                  id: "M4",
                  label: "M4",
                },
                {
                  id: "M2",
                  label: "M2",
                },
                {
                  id: "M1",
                  label: "M1",
                },
              ],
            },
          ],
        },
      ],
      callPagination: false,
      dateRange: {
        startDate: null,
        endDate: null,
      },
      escalations: [],
      initSelectedLevels: [],
      missed_hot_pocketpagination: false,
      referralpagination: false,
      delayspagination: false,
      todayspagination: false,
      waitingpagination: false,
      filteredpagination: false,
      untappedpagination: false,
      activeTabLInk: 0,
      nonM7activeTabLInk: 0,
      M7activeTabLInk: 0,
      updatepagination: false,
      missedcallpagination: false,
      untrackedpagination: false,
      calllogpagination: false,
      netenquiriesPagination: true,
      flagpagination: false,
      levelsFilter: "",
      // Untapped: [],
      Delays: [],
      Today: [],
      Waiting: [],
      ToUpdate: [],
      Untracked: [],
      CallLog: [],
      MissedCalls: [],
      delaysTotal: "0",
      updatePvalue: 1,
      netEnquiriesPvalue: 1,
      missedcallPvalue: 1,
      untrackedPvalue: 1,
      calllogPvalue: 1,
      delayPvalue: 1,
      todayPvalue: 1,
      waitingPvalue: 1,
      untappedPvalue: 1,
      missed_hot_pocketPvalue: 1,
      referralPvalue: 1,
      filteredPvalue: 1,
      flagPvalue: 1,
      delayPtotal: 0,
      netEnquiriesPtotal: 0,
      todayPtotal: 0,
      waitingPtotal: 0,
      missed_hot_pocketPtotal: 0,
      referralPtotal: 0,
      untappedPtotal: 0,
      updatePtotal: 0,
      filteredPtotal: 1,
      missedcallPtotal: 0,
      untrackedPtotal: 0,
      calllogPtotal: 0,
      flagPtotal: 0,
      untrackedcalls: [
        {
          number: "9876354632",
          type: "OUTGOING",
          duration: "00:05:49",
          time: "April 30, 2019 1:12 pm",
        },
        {
          number: "7834534632",
          type: "INCOMING",
          duration: "00:05:49",
          time: "April 30, 2019 1:12 pm",
        },
        {
          number: "92345236322",
          type: "OUTGOING",
          duration: "00:05:49",
          time: "April 30, 2019 1:12 pm",
        },
      ],
      calllogs: [
        {
          candidate_id: 2,
          name: "Vishal",
          type: "OUTGOING",
          duration: "00:02:23",
          time: "April 30, 2019 1:14 pm",
        },
        {
          candidate_id: 6,
          name: "Pawan Alreja",
          type: "INCOMING",
          duration: "00:05:49",
          time: "April 30, 2019 1:20 pm",
        },
      ],
      missedcallslength: 0,
      flagSection: [],
      flagLabel: "Flag [0]",
      activeTabcallLInk: 0,
      Flagsgshowtab: false,
    };
  },
  created() {},
  watch: {
    LevelsSelect(value) {
      console.log(value);

      if (value === "All") {
        console.log("All");
        this.allTabslevels = true;
        this.M7abovelevels = false;
        this.nonM7abovelevels = false;
        this.single_fetch_api();
      }
      if (value === "Onboarding") {
        console.log("Onboarding");
        this.M7abovelevels = true;
        this.allTabslevels = false;
        this.nonM7abovelevels = false;
        this.single_fetch_api();
      }
      if (value === "SR-M7") {
        console.log("SR-M7");
        this.M7abovelevels = true;
        this.allTabslevels = false;
        this.nonM7abovelevels = false;
        this.single_fetch_api();
      }
      if (value === "Non M7") {
        console.log("Non M7");
        this.allTabslevels = false;
        this.M7abovelevels = false;
        this.nonM7abovelevels = true;
        this.single_fetch_api();
      }
      if (value === "SR-M8,M9,M10") {
        console.log("SR-M8,M9,M10");
        this.M7abovelevels = true;
        this.allTabslevels = false;
        this.nonM7abovelevels = false;
        this.single_fetch_api();
      }
    },
    delayPvalue(value) {
      this.delayPvalueFunction(value);
    },
    todayPvalue(value) {
      this.todayPvalueFunction(value);
    },
    waitingPvalue(value) {
      this.waitingPvalueFunction(value);
    },
    untappedPvalue(value) {
      this.untappedPvalueFunction(value);
    },
    missed_hot_pocketPvalue(value) {
      this.missed_hot_pocketPvalueFunction(value);
    },
    referralPvalue(value) {
      this.referralPvalueFunction(value);
    },
    untrackedPvalue(value) {
      this.untrackedPvalueFunction(value);
    },
    missedcallPvalue(value) {
      this.missedcallPvalueFunction(value);
    },
    flagPvalue(value) {
      this.flagPvalueFunction(value);
    },
    netEnquiriesPvalue(value) {
      this.NetenquiresPvalueFunction(value);
    },
    filteredPvalue() {
      // if (value !== 0) {
      this.updateValues();
      // }
    },
  },
  methods: {
    firstData() {
      this.updatePvalue = 1;
      this.netEnquiriesPvalue = 1;
      this.missedcallPvalue = 1;
      this.untrackedPvalue = 1;
      this.calllogPvalue = 1;
      this.delayPvalue = 1;
      this.todayPvalue = 1;
      this.waitingPvalue = 1;
      this.untappedPvalue = 1;
      this.missed_hot_pocketPvalue = 1;
      this.referralPvalue = 1;
      this.filteredPvalue = 1;
      this.flagPvalue = 1;
    },
    m7Team(team) {
      this.cities = [];
      this.selected_city = "";
      console.log("team", team);
      this.selectedTeam = team;
      this.single_fetch_api();
      this.nonm7teamselectbuttoncolor = [
        {
          background: "#ffffff",
          color: "rgb(199, 42, 117)",
          border: "1px solid rgb(199, 42, 117)",
        },
      ];
      this.m7teamselectbuttoncolor = [
        {
          background: "rgb(199, 42, 117)",
          color: "#ffffff",
          border: "1px solid rgb(199, 42, 117)",
        },
      ];
    },
    nonm7Team(team) {
      console.log("dgydg", team);
      this.m7teamselectbuttoncolor = [
        {
          background: "#ffffff",
          color: "rgb(199, 42, 117)",
          border: "1px solid rgb(199, 42, 117)",
        },
      ];
      this.nonm7teamselectbuttoncolor = [
        {
          background: "rgb(199, 42, 117)",
          color: "#ffffff",
          border: "1px solid rgb(199, 42, 117)",
        },
      ];
      this.selectedTeam = team;
      this.cities = [];
      this.selected_city = "";
      this.single_fetch_api();
      console.log("team", team);
    },
    calldecidePagination() {
      switch (this.activeTabcallLInk) {
        case 1:
          this.untrackedPvalueFunction();
          break;
        case 2:
          this.missedcallPvalueFunction();
          break;
      }
    },
    decidePagination() {
      switch (this.activeTabLInk) {
        case 1:
          this.missed_hot_pocketPvalueFunction();
          break;
        case 2:
          this.untappedPvalueFunction();
          break;
        case 3:
          this.delayPvalueFunction();
          break;
        case 4:
          this.todayPvalueFunction();
          break;
        case 5:
          this.waitingPvalueFunction();
          break;
        case 6:
          this.flagPvalueFunction();
          break;
        case 7:
          this.NetenquiresPvalueFunction();
          break;
        case 8:
          this.referralPvalueFunction();
          break;
      }
    },
    nonM7decidePagination() {
      switch (this.nonM7activeTabLInk) {
        case 1:
          this.missed_hot_pocketPvalueFunction();
          break;
        case 2:
          this.untappedPvalueFunction();
          break;
        case 3:
          this.delayPvalueFunction();
          break;
        case 4:
          this.todayPvalueFunction();
          break;
        case 5:
          this.waitingPvalueFunction();
          break;
        case 6:
          this.flagPvalueFunction();
          break;
        case 7:
          this.referralPvalueFunction();
          break;
      }
    },
    M7decidePagination() {
      switch (this.M7activeTabLInk) {
        case 1:
          this.missed_hot_pocketPvalueFunction();
          break;
        case 2:
          this.delayPvalueFunction();
          break;
        case 3:
          this.todayPvalueFunction();
          break;
        case 4:
          this.waitingPvalueFunction();
          break;
        case 5:
          this.referralPvalueFunction();
          break;
      }
    },
    delayPvalueFunction(value) {
      this.$vs.loading();
      console.log(value);
      let obj = {};
      if (this.levelsFilter !== "") {
        obj = this.levelsFilter;
      }
      obj.page = this.delayPvalue;
      // obj.m7_status = this.selectedTeam
      //getSRDelaysPagination
      let url = "";
      if(this.LevelsSelect === "All"){
        url = `${constants.SERVER_API}getSRDelaysPagination`;
      }
      if(this.LevelsSelect === "SR-M7"){
        url = `${constants.SERVER_API}getSRMSevenDelays`;
      }
      if(this.LevelsSelect === "Non M7"){
        url = `${constants.SERVER_API}getSRDelaysWithLevels?level_type=below_m7`;
      }
      if(this.LevelsSelect === "SR-M8,M9,M10"){
        url = `${constants.SERVER_API}getSRDelaysWithLevels?level_type=above_m7`;
      }
      if(this.LevelsSelect === "Onboarding"){
        url = `${constants.SERVER_API}getSROnBoardDelays`;
      }
      axios
        .get(url, {
          params: obj,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.$store.commit("PUSH_TO_DELAYS", response.data.data);
          this.Delays = response.data.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
    },
    todayPvalueFunction(value) {
      this.$vs.loading();
      console.log(value);
      let obj = {};
      if (this.levelsFilter !== "") {
        obj = this.levelsFilter;
      }
      obj.page = this.todayPvalue;
      // obj.m7_status = this.selectedTeam
      //getSRTodayPagination
      let url = "";
      if(this.LevelsSelect === "All"){
        url = `${constants.SERVER_API}getSRTodayPagination`;
      }
      if(this.LevelsSelect === "SR-M7"){
        url = `${constants.SERVER_API}getSRMSevenToday`;
      }
      if(this.LevelsSelect === "Non M7"){
        url = `${constants.SERVER_API}getSRTodayWithLevels?level_type=below_m7`;
      }
      if(this.LevelsSelect === "SR-M8,M9,M10"){
        url = `${constants.SERVER_API}getSRTodayWithLevels?level_type=above_m7`;
      }
      if(this.LevelsSelect === "Onboarding"){
        url = `${constants.SERVER_API}getSROnBoardToday`;
      }
      axios
        .get(url, {
          params: obj,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.$store.commit("PUSH_TO_TODAY", response.data.data);
          this.$vs.loading.close();
        })
        .catch((error) => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
          this.$vs.loading.close();
        });
      // this.$store.commit('DASHBOARD_PAGINATION', gettodays.data)
    },
    waitingPvalueFunction(value) {
      this.$vs.loading();
      console.log(value);
      let obj = {};
      if (this.levelsFilter !== "") {
        obj = this.levelsFilter;
      }
      obj.page = this.waitingPvalue;
      // obj.m7_status = this.selectedTeam
      //getSRWaitingPagination
      let url = "";
      if(this.LevelsSelect === "All"){
        url = `${constants.SERVER_API}getSRWaitingPagination`;
      }
      if(this.LevelsSelect === "SR-M7"){
        url = `${constants.SERVER_API}getSRMSevenWaiting`;
      }
      if(this.LevelsSelect === "Non M7"){
        url = `${constants.SERVER_API}getSRWaitingWithLevels?level_type=below_m7`;
      }
      if(this.LevelsSelect === "SR-M8,M9,M10"){
        url = `${constants.SERVER_API}getSRWaitingWithLevels?level_type=above_m7`;
      }
      if(this.LevelsSelect === "Onboarding"){
        url = `${constants.SERVER_API}getSROnBoardingWaiting`;
      }
      axios
        .get(url, {
          params: obj,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.$store.commit("PUSH_TO_WAITING", response.data.data);
          this.$vs.loading.close();
        })
        .catch((error) => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
          this.$vs.loading.close();
        });
    },
    untappedPvalueFunction(value) {
      this.$vs.loading();
      console.log(value);
      let obj = {};
      if (this.levelsFilter !== "") {
        obj = this.levelsFilter;
      }
      obj.page = this.untappedPvalue;
      axios
        .get(`${constants.SERVER_API}getSRUntappedPagination`, {
          params: obj,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.$store.commit("PUSH_TO_UNTAPPED", response.data.data);
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
    },
    missed_hot_pocketPvalueFunction(value) {
      this.$vs.loading();
      console.log(value);
      let obj = {};
      if (this.levelsFilter !== "") {
        obj = this.levelsFilter;
      }
      obj.page = this.missed_hot_pocketPvalue;
      // obj.m7_status = this.selectedTeam
      //getSRMHPsPagination
      let url = "";
      if(this.LevelsSelect === "All"){
        url = `${constants.SERVER_API}getSRMHPsPagination?level_type=${this.LevelsSelect}`;
      }
      if(this.LevelsSelect === "SR-M7"){
        url = `${constants.SERVER_API}getSRMSevenMHPs`;
      }
      if(this.LevelsSelect === "SR-M8,M9,M10"){
        url = `${constants.SERVER_API}getSRMhpsWithLevels?level_type=above_m7`;
      }
      if(this.LevelsSelect === "Non M7"){
        url = `${constants.SERVER_API}getSRMhpsWithLevels?level_type=below_m7`;
      }
      if(this.LevelsSelect === "Onboarding"){
        url = `${constants.SERVER_API}getSROnboardMHPs`;
      }
      axios
        .get(url, {
          params: obj,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.$store.commit("PUSH_TO_MHP", response.data.data);
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
    },
    referralPvalueFunction(value) {
      this.$vs.loading();
      console.log(value);
      let obj = {};
      if (this.levelsFilter !== "") {
        obj = this.levelsFilter;
      }
      obj.page = this.referralPvalue;
      // obj.m7_status = this.selectedTeam
      //getReferralPagination
      let url = "";
      if(this.LevelsSelect === "All"){
        url = `${constants.SERVER_API}getReferralPagination`;
      }
      if(this.LevelsSelect === "SR-M7"){
        url = `${constants.SERVER_API}getSRMSevenMHPsReferrals`;
      }
      if(this.LevelsSelect === "Non M7"){
        url = `${constants.SERVER_API}getSRMhpsWithLevelsReferrals?level_type=below_m7`;
      }
      if(this.LevelsSelect === "SR-M8,M9,M10"){
        url = `${constants.SERVER_API}getSRMhpsWithLevelsReferrals?level_type=above_m7`;
      }
      if(this.LevelsSelect === "Onboarding"){
        url = `${constants.SERVER_API}getSROnboardMHPsReferrals`;
      }
      axios
        .get(url, {
          params: obj,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.$store.commit("PUSH_TO_REFERRALS", response.data.data);
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
    },
    untrackedPvalueFunction(value) {
      this.$vs.loading();
      console.log("untracked", value);
      let obj = {};
      if (this.levelsFilter !== "") {
        obj = this.levelsFilter;
      }
      obj.page = this.untrackedPvalue;
      axios
        .get(
          `${constants.SERVER_API}getUntrackedPagination?page=${this.untrackedPvalue}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          }
        )
        .then((response) => {
          console.log("untracked", response);
          this.$store.commit("PUSH_TO_UNTRACKED", response.data.data);
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
    },
    missedcallPvalueFunction(value) {
      this.$vs.loading();
      console.log("missed", value);
      let sendarr = [];
      let obj = {};
      if (this.levelsFilter !== "") {
        obj = this.levelsFilter;
      }
      obj.page = this.missedcallPvalue;
      axios
        .get(
          `${constants.SERVER_API}getMissedPagination?page=${this.missedcallPvalue}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          }
        )
        .then((response) => {
          console.log("missed", response);
          response.data.data.forEach((data_point) => {
            // console.log(data_point);
            data_point.category_type = "missed_calls";
            data_point.mobile = [];
            data_point.emails = [];
            data_point.engagements = [];
            sendarr.push(data_point.call_data);
          });
          this.$store.commit("PUSH_TO_MISSED_CALLS", sendarr);
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
    },
    flagPvalueFunction() {
      this.$vs.loading();
      let obj = {};
      if (this.levelsFilter !== "") {
        obj = this.levelsFilter;
      }
      obj.page = this.flagPvalue;
      axios
        .get(`${constants.SERVER_API}getSRFlagPagination`, {
          params: obj,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.flagSection = response.data.data;
          this.$vs.loading.close();
          // this.$store.commit("PUSH_TO_MHP", response.data.dashboard_data.data);
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
    },
    NetenquiresPvalueFunction(value) {
      console.log(value);
      this.$vs.loading();
      let obj = {};
      if (this.levelsFilter !== "") {
        obj = this.levelsFilter;
      }
      obj.page = this.netEnquiriesPvalue;
      axios
        .get(`${constants.SERVER_API}getNetEnquiriesPagination`, {
          params: obj,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          // this.flagSection = response.data.dashboard_data.data;
          this.$store.commit("PUSH_TO_NET_ENQUIRIES", response.data.data);
          this.netEnquiriesData = response.data.data;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
    },

    getNetEnquires() {
      this.$vs.loading();
      axios
        .get(`${constants.SERVER_API}getDashboardNetEnquiries`, {
          // params: { page: this.netEnquiriesPvalue },
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
          // headers: { Authorization: `Bearer ${constants.Imran_Access_token}` }
        })
        .then((response) => {
          // console.log(response, "<- net enquiries");
          // this.netEnquiriesPtotal = response.data.last_page;
          // document
          //   .getElementById("NetEnquiriesTab")
          //   .getElementsByTagName("span")[0].innerText =
          //   "Net Enquiries [" + response.data.length + "]";
          // document
          //   .getElementById("NetEnquiriesTab")
          //   .getElementsByTagName("span")[0].innerText =
          //   "Net Enquiries [" + response.data.length + "]";
          response.data.forEach((data_point) => {
            // console.log(data_point);
            data_point.category_type = "NetEnquiry";
            // data_point.mobile = data_point.mobile.split(",");
            data_point.dialingNumber = "";
            // if (data_point.mobile.length > 0) {
            //   data_point.dialingNumber = this.getLastTenDigits(
            //     data_point.mobile[0]
            //   );
            // }
            if (data_point.mobile !== null && data_point.mobile !== "") {
              data_point.dialingNumber = this.getLastTenDigits(
                data_point.mobile
              );
            }
            data_point.email = data_point.email.split(",");
            data_point.Emails = "";
            if (data_point.email.length > 0) {
              data_point.Emails = data_point.email[0];
            }
            data_point.engagements = [];
          });
          // var unique = response.data.data
          //   .map(lead => {
          //     return {
          //       count: 1,
          //       name: lead.name
          //     };
          //   })
          //   .reduce((a, b) => {
          //     a[b.name] = (a[b.name] || 0) + b.count;
          //     return a;
          //   }, {});
          // console.log(unique);
          if (this.$route.path !== "/queue") {
            this.$store.commit("PUSH_TO_NET_ENQUIRIES", response.data);
          }
          this.$vs.loading.close();
          // this.Untapped = response.data.untapped.data;
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
    },
    refresh() {
      this.initSelectedLevels = [];
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
      this.$store.commit("CLEAR_MWB");
      this.filteredPtotal = 0;
      console.log("called");
      document
        .getElementById("filteredTab")
        .getElementsByTagName("span")[0].innerText = "Filtered [" + 0 + "]";
    },
    changepagination(pagination) {
      switch (pagination) {
        case "Delays":
          this.missed_hot_pocketpagination = false;
          this.delayspagination = true;
          this.todayspagination = false;
          this.waitingpagination = false;
          this.untappedpagination = false;
          this.filteredpagination = false;
          this.netenquiriesPagination = false;
          this.flagpagination = false;
          this.referralpagination = false;
          break;
        case "Today":
          this.missed_hot_pocketpagination = false;
          this.todayspagination = true;
          this.delayspagination = false;
          this.waitingpagination = false;
          this.untappedpagination = false;
          this.filteredpagination = false;
          this.netenquiriesPagination = false;
          this.flagpagination = false;
          this.referralpagination = false;
          break;
        case "Waiting":
          this.missed_hot_pocketpagination = false;
          this.waitingpagination = true;
          this.delayspagination = false;
          this.todayspagination = false;
          this.untappedpagination = false;
          this.filteredpagination = false;
          this.netenquiriesPagination = false;
          this.flagpagination = false;
          this.referralpagination = false;
          break;
        // case "Untapped":
        //   this.missed_hot_pocketpagination = false;
        //   this.untappedpagination = true;
        //   this.waitingpagination = false;
        //   this.delayspagination = false;
        //   this.todayspagination = false;
        //   this.filteredpagination = false;
        //   this.netenquiriesPagination = false;
        //   this.flagpagination = false;
        //   this.referralpagination = false;
        //   break;
        case "mhp":
          this.missed_hot_pocketpagination = true;
          this.untappedpagination = false;
          this.waitingpagination = false;
          this.delayspagination = false;
          this.todayspagination = false;
          this.filteredpagination = false;
          this.netenquiriesPagination = false;
          this.flagpagination = false;
          this.referralpagination = false;
          break;
        case "Filtered":
          this.missed_hot_pocketpagination = false;
          this.filteredpagination = true;
          this.untappedpagination = false;
          this.waitingpagination = false;
          this.delayspagination = false;
          this.todayspagination = false;
          this.netenquiriesPagination = false;
          this.flagpagination = false;
          this.referralpagination = false;
          break;
        case "NetEnquiries":
          this.missed_hot_pocketpagination = false;
          this.netenquiriesPagination = true;
          this.filteredpagination = false;
          this.untappedpagination = false;
          this.waitingpagination = false;
          this.delayspagination = false;
          this.todayspagination = false;
          this.flagpagination = false;
          this.referralpagination = false;
          break;
        case "Flag":
          this.missed_hot_pocketpagination = false;
          this.netenquiriesPagination = false;
          this.filteredpagination = false;
          this.untappedpagination = false;
          this.waitingpagination = false;
          this.delayspagination = false;
          this.todayspagination = false;
          this.flagpagination = true;
          this.referralpagination = false;
          break;
        case "Referral":
          this.missed_hot_pocketpagination = false;
          this.netenquiriesPagination = false;
          this.filteredpagination = false;
          this.untappedpagination = false;
          this.waitingpagination = false;
          this.delayspagination = false;
          this.todayspagination = false;
          this.flagpagination = false;
          this.referralpagination = true;
      }
    },
    changepagination2(pagination) {
      switch (pagination) {
        case "Missed calls":
          this.updatepagination = false;
          this.missedcallpagination = true;
          this.untrackedpagination = false;
          this.calllogpagination = false;
          break;
        case "ToUpdate":
          this.updatepagination = true;
          this.missedcallpagination = false;
          this.untrackedpagination = false;
          this.calllogpagination = false;
          break;
        case "Untracked":
          this.updatepagination = false;
          this.missedcallpagination = false;
          this.untrackedpagination = true;
          this.calllogpagination = false;
          break;
        case "Calllog":
          this.updatepagination = false;
          this.missedcallpagination = false;
          this.untrackedpagination = false;
          this.calllogpagination = true;
      }
    },
    analizePagination() {
      if (this.filteredPvalue === 1) {
        this.updateValues();
      } else {
        this.filteredPvalue = 1;
      }
    },
    updateValues() {
      let levels = [];
      this.initSelectedLevels.forEach((sort) => {
        if (sort === "All") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            levels.push(child.label);
          });
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            levels.push(child.label);
          });
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            levels.push(child.label);
          });
          this.treeDataLevels[0].children[3].children.forEach((child) => {
            levels.push(child.label);
          });
        } else if (sort === "P1") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            levels.push(child.label);
          });
        } else if (sort === "P2") {
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            levels.push(child.label);
          });
        } else if (sort === "P3") {
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            levels.push(child.label);
          });
        } else if (sort === "P4") {
          this.treeDataLevels[0].children[3].children.forEach((child) => {
            levels.push(child.label);
          });
        } else {
          levels.push(sort);
        }
      });
      // console.log(levels)
      // console.log(this.dateRange)
      if (levels.length === 0) {
        this.$vs.notify({
          title: "Select a level",
          text: "Select atleast 1 level to continue",
          color: "warning",
        });
      } else {
        let startdate = "";
        let enddate = "";
        if (this.dateRange.startDate !== null) {
          startdate = this.datatoTimestamp(this.dateRange.startDate);
        }
        if (this.dateRange.endDate !== null) {
          enddate = this.datatoTimestamp(this.dateRange.endDate);
        }
        levels = levels.join();
        let url = `${
          constants.SERVER_API
        }getFilteredData?levels=${encodeURIComponent(
          levels
        )}&from_date=${startdate}&to_date=${enddate}&page=${
          this.filteredPvalue
        }`;
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            // console.log(response)
            this.filteredPtotal = response.data.dashboard_data.last_page;
            document
              .getElementById("filteredTab")
              .getElementsByTagName("span")[0].innerText =
              "Filtered [" + response.data.dashboard_data.total + "]";
            response.data.dashboard_data.data.forEach((data_point) => {
              data_point.category_type = response.data.dashboard_category;
              data_point.mobile = [];
              data_point.emails = [];
              data_point.engagements = [];
            });
            this.$store.commit(
              "MOUNT_MWB_LEADS",
              response.data.dashboard_data.data
            );
          })
          .catch((error) => {
            this.handleError(error);
          });
      }
    },
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      return ms;
    },
    timetoTimestamp(incoming) {
      return new Date(incoming).setHours(0, 0, 0, 0) / 1000;
    },
    findDuplicates(category) {
      // console.log(category);
      let array_element = this.$store.state.MwbLeads;
      switch (category) {
        case "untracked_calls":
          array_element = this.$store.state.UnTracked;
          break;
        case "to_update":
          array_element = this.$store.state.ToUpdate;
          break;
        case "missed_calls":
          array_element = this.$store.state.MissedCalls;
      }
      // console.log(array_element);
      let copy_array = [];
      array_element.forEach((el) => {
        copy_array.push(el);
      });
      let name = "";
      let count = 0;
      let idtocount = "";
      let status = [];
      if (category === "untracked_calls") {
        copy_array.forEach((first_el, index) => {
          if (first_el.category_type === category) {
            if (first_el.phone_number !== name) {
              name = first_el.phone_number;
              count = 0;
              idtocount = category + index;
              status.push({
                count: 1,
                id: category + index,
                status: "count",
              });
            } else {
              count = count + 1;
              status.forEach((stat) => {
                if (stat.id === idtocount) {
                  stat.count = stat.count + 1;
                }
              });
              status.push({
                id: category + index,
                status: "remove",
              });
            }
          }
        });
      } else if (category === "to_update") {
        copy_array.forEach((first_el, index) => {
          if (first_el.category_type === category) {
            if (first_el.person_name !== name) {
              name = first_el.person_name;
              count = 0;
              idtocount = category + index;
              status.push({
                count: 1,
                id: category + index,
                status: "count",
              });
            } else {
              count = count + 1;
              status.forEach((stat) => {
                if (stat.id === idtocount) {
                  stat.count = stat.count + 1;
                }
              });
              status.push({
                id: category + index,
                status: "remove",
              });
            }
          }
        });
      } else if (category === "missed_calls") {
        copy_array.forEach((first_el, index) => {
          if (first_el.category_type === category) {
            // console.log(first_el.mwb.id, "<- first e");
            if (first_el.mwb.id === undefined) {
              if (first_el.call_data.phone_number !== name) {
                name = first_el.call_data.phone_number;
                count = 0;
                idtocount = category + index;
                status.push({
                  count: 1,
                  id: category + index,
                  status: "count",
                });
              } else {
                count = count + 1;
                status.forEach((stat) => {
                  if (stat.id === idtocount) {
                    stat.count = stat.count + 1;
                  }
                });
                status.push({
                  id: category + index,
                  status: "remove",
                });
              }
            } else {
              if (first_el.mwb.person_name !== name) {
                name = first_el.mwb.person_name;
                count = 0;
                idtocount = category + index;
                status.push({
                  count: 1,
                  id: category + index,
                  status: "count",
                });
              } else {
                count = count + 1;
                status.forEach((stat) => {
                  if (stat.id === idtocount) {
                    stat.count = stat.count + 1;
                  }
                });
                status.push({
                  id: category + index,
                  status: "remove",
                });
              }
            }
          }
        });
      }
      setTimeout(() => {
        status.forEach((stat) => {
          if (stat.status === "count") {
            // console.log(
            //   document
            //     .getElementById(stat.id)
            //     .getElementsByTagName("div")[3]
            //     .getElementsByTagName("div")
            // );
            document
              .getElementById(stat.id)
              .getElementsByTagName("div")[3].innerHTML = `${
              document.getElementById(stat.id).getElementsByTagName("div")[3]
                .innerHTML
            } <div class="con-vs-chip vs-chip-danger con-color" style="color: rgba(255, 255, 255, 0.9);"><span class="text-chip vs-chip--text"><b>${
              stat.count
            }</b></span></div>`;
          } else if (stat.status === "remove") {
            document.getElementById(stat.id).style.display = "none";
          }
        });
      }, 300);
    },
    single_fetch_api() {
      // console.log(level);
      this.getIvrCallLogs();
      this.firstData();
      let url = "";
      // if(level === undefined || level === "") {
      //   level = this.selectedTeam
      // }
      if (this.LevelsSelect === "All") {
        url = `${constants.SERVER_API}getSrDataWithLevels?level_type=all`;
      }
      if (this.LevelsSelect === "Onboarding") {
        url = `${constants.SERVER_API}getSRDashBoardOnboardingData`;
      }
      if (this.LevelsSelect === "SR-M7") {
        url = `${constants.SERVER_API}getSRMSevenData`;
      }
      if (this.LevelsSelect === "Non M7") {
        url = `${constants.SERVER_API}getSrDataWithLevels?level_type=below_m7`;
      }
      if (this.LevelsSelect === "SR-M8,M9,M10") {
        url = `${constants.SERVER_API}getSrDataWithLevels?level_type=above_m7`;
      }
      this.$vs.loading();

      //testing for live
      // let obj = {};
      // if (this.levelsFilter !== "") {
      //   obj = this.levelsFilter;
      // }
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);

          if (
            this.LevelsSelect === "Onboarding" ||
            this.LevelsSelect === "SR-M7" ||
            this.LevelsSelect === "SR-M8,M9,M10"
          ) {
            this.M7assignTostore(response);
          } else {
            this.assignTostore(response);
          }

          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    assignTostore(response) {
      if (this.LevelsSelect === "All") {
        this.escalations = response.data.escalations;
        // this.escalationText = `Efcalations [${response.data.escalations.length}]`;
        // console.log(this.escalationText);
        document
          .getElementById("EscalationsTab")
          .getElementsByTagName("span")[0].innerText =
          "Escalations [" + response.data.escalations.length + "]";
        console.log(this.escalations);
        // this.untappedPtotal = response.data.untapped.last_page;
        // document
        //   .getElementById("untappedTab")
        //   .getElementsByTagName("span")[0].innerText =
        //   "Untapped [" + response.data.untapped.total + "]";
        // response.data.untapped.data.forEach((data_point) => {
        //   data_point.category_type = response.data.dashboard_category;
        //   data_point.mobile = [];
        //   data_point.emails = [];
        //   data_point.engagements = [];
        // });
        // this.$store.commit("PUSH_TO_UNTAPPED", response.data.untapped.data);
        // this.Untapped = response.data.untapped.data;

        this.flagPtotal = response.data.flag.last_page;
        // this.flagLabel = `Flag [${response.data.flag.length}]`;
        document
          .getElementById("FlagTab")
          .getElementsByTagName("span")[0].innerText =
          "Flag [" + response.data.flag.total + "]";
        this.flagSection = response.data.flag.data;
        //MHP
        this.missed_hot_pocketPtotal = response.data.mhps.last_page;
        document
          .getElementById("mhptab")
          .getElementsByTagName("span")[0].innerText =
          "MHP [" + response.data.mhps.total + "]";
        response.data.mhps.data.forEach((data_point) => {
          data_point.category_type = response.data.dashboard_category;
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
        });

        this.$store.commit("PUSH_TO_MHP", response.data.mhps.data);

        //Referrals
        this.referralPtotal = response.data.referrals.last_page;
        document
          .getElementById("referralstab")
          .getElementsByTagName("span")[0].innerText =
          "Referrals [" + response.data.referrals.total + "]";
        response.data.referrals.data.forEach((data_point) => {
          data_point.category_type = response.data.dashboard_category;
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
        });
        this.$store.commit("PUSH_TO_REFERRALS", response.data.referrals.data);
        // this.Untapped = response.data.untapped.data;
        this.missedcallPtotal = response.data.missed_calls.last_page;
        document
          .getElementById("MissedcallsTab")
          .getElementsByTagName("span")[0].innerText =
          "Missed Calls [" + response.data.missed_calls.total + "]";
        let sendarr = [];
        response.data.missed_calls.data.forEach((data_point) => {
          // console.log(data_point);
          data_point.category_type = "missed_calls";
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
          sendarr.push(data_point.call_data);
          // if (
          //   Object.keys(data_point.mwb).length === 0 &&
          //   data_point.mwb.constructor === Object
          // ) {
          //   // console.log("this lead is untracked missed call");
          //   data_point.mwb = data_point.call_data;
          //   data_point.mwb.category_type = "missed_calls";
          //   data_point.mwb.mobile = [];
          //   data_point.mwb.emails = [];
          //   data_point.mwb.engagements = [];
          //   sendarr.push(data_point.mwb);
          // } else {
          //   data_point.mwb.category_type = "missed_calls";
          //   data_point.mwb.mobile = [];
          //   data_point.mwb.emails = [];
          //   data_point.mwb.engagements = [];
          //   data_point.mwb.phone_number = data_point.call_data.phone_number;
          //   data_point.mwb.time = data_point.call_data.time;
          //   sendarr.push(data_point.mwb);
          // }
          // data_point.mwb.contact_type =
          // response.data.dashboard_data.call_data.contact_type;
        });
        this.$store.commit(
          "PUSH_TO_MISSED_CALLS",
          // response.data.dashboard_data
          sendarr
        );
        this.MissedCalls = sendarr;

        this.todayPtotal = response.data.today.last_page;
        document
          .getElementById("todayTab")
          .getElementsByTagName("span")[0].innerText =
          "Today [" + response.data.today.total + "]";
        response.data.today.data.forEach((data_point) => {
          data_point.category_type = "today";
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
        });
        // console.log("today ", response);
        this.$store.commit("PUSH_TO_TODAY", response.data.today.data);
        this.Today = response.data.today.data;

        this.waitingPtotal = response.data.waiting.last_page;
        document
          .getElementById("waitingTab")
          .getElementsByTagName("span")[0].innerText =
          "Waiting [" + response.data.waiting.total + "]";
        response.data.waiting.data.forEach((data_point) => {
          data_point.category_type = "waiting";
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
        });
        // console.log("waiting", response);
        this.$store.commit("PUSH_TO_WAITING", response.data.waiting.data);
        this.Waiting = response.data.waiting.data;

        this.delayPtotal = response.data.delays.last_page;
        document
          .getElementById("delaysTab")
          .getElementsByTagName("span")[0].innerText =
          "Delays [" + response.data.delays.total + "]";
        response.data.delays.data.forEach((data_point) => {
          data_point.category_type = "delays";
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
        });
        // console.log("delays", response);
        this.$store.commit("PUSH_TO_DELAYS", response.data.delays.data);
        this.Delays = response.data.delays.data;

        document
          .getElementById("ToupdateTab")
          .getElementsByTagName("span")[0].innerText =
          "To Update [" + response.data.to_update.length + "]";
        // console.log("getupdate", response);
        response.data.to_update.forEach((data_point) => {
          data_point.category_type = "to_update";
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
        });
        this.$store.commit("PUSH_TO_UPDATE", response.data.to_update);
        this.ToUpdate = response.data.to_update;

        this.untrackedPtotal = response.data.untracked.last_page;
        document
          .getElementById("UntrackedTab")
          .getElementsByTagName("span")[0].innerText =
          " Untracked [" + response.data.untracked.total + "]";
        response.data.untracked.data.forEach((data_point) => {
          data_point.category_type = "untracked";
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
        });
        this.$store.commit("PUSH_TO_UNTRACKED", response.data.untracked.data);
        this.Untracked = response.data.untracked.data;

        document
          .getElementById("CalllogsTab")
          .getElementsByTagName("span")[0].innerText =
          " Call Logs [" + response.data.call_logs.length + "]";
        response.data.call_logs.forEach((data_point) => {
          data_point.category_type = "call_logs";
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
        });
        this.$store.commit("PUSH_TO_CALL_LOG", response.data.call_logs);
        this.CallLog = response.data.call_logs;
        // net enquiries
        this.netEnquiriesPtotal = response.data.net_enquiries.last_page;
        if(this.ne_data_allowed == true){
        document
          .getElementById("NetEnquiriesTab")
          .getElementsByTagName("span")[0].innerText =
          " Net Enquiries [" + response.data.net_enquiries.total + "]";
        }
        response.data.net_enquiries.data.forEach((data_point) => {
          data_point.category_type = "net_enquiries";
          // data_point.mobile = data_point.mobile.split(",");
          data_point.dialingNumber = "";
          // if (data_point.mobile.length > 0) {
          //   data_point.dialingNumber = this.getLastTenDigits(
          //     data_point.mobile[0]
          //   );
          // }
          if (data_point.mobile !== null && data_point.mobile !== "") {
            data_point.dialingNumber = this.getLastTenDigits(data_point.mobile);
          }
          data_point.email = data_point.email.split(",");
          data_point.Emails = "";
          if (data_point.email.length > 0) {
            data_point.Emails = data_point.email[0];
          }
          data_point.engagements = [];
        });
        if (this.$route.path !== "/queue") {
          this.$store.commit(
            "PUSH_TO_NET_ENQUIRIES",
            response.data.net_enquiries.data
          );
        this.netEnquiriesData = response.data.net_enquiries.data;
        }
        if (this.callPagination) {
          this.decidePagination();
          this.calldecidePagination();
        }
        this.missedcallpagination = true;
      }
      if (this.LevelsSelect === "Non M7") {
        this.escalations = response.data.escalations;
        // this.escalationText = `Efcalations [${response.data.escalations.length}]`;
        // console.log(this.escalationText);
        document
          .getElementById("nonM7EscalationsTab")
          .getElementsByTagName("span")[0].innerText =
          "Escalations [" + response.data.escalations.length + "]";
        console.log(this.escalations);
        // this.untappedPtotal = response.data.untapped.last_page;
        // document
        //   .getElementById("nonM7untappedTab")
        //   .getElementsByTagName("span")[0].innerText =
        //   "Untapped [" + response.data.untapped.total + "]";
        // response.data.untapped.data.forEach((data_point) => {
        //   data_point.category_type = response.data.dashboard_category;
        //   data_point.mobile = [];
        //   data_point.emails = [];
        //   data_point.engagements = [];
        // });
        // this.$store.commit("PUSH_TO_UNTAPPED", response.data.untapped.data);
        // this.Untapped = response.data.untapped.data;

        this.flagPtotal = response.data.flag.last_page;
        // this.flagLabel = `Flag [${response.data.flag.length}]`;
        document
          .getElementById("nonM7FlagTab")
          .getElementsByTagName("span")[0].innerText =
          "Flag [" + response.data.flag.total + "]";
        this.flagSection = response.data.flag.data;
        //MHP
        this.missed_hot_pocketPtotal = response.data.mhps.last_page;
        document
          .getElementById("nonM7mhptab")
          .getElementsByTagName("span")[0].innerText =
          "MHP [" + response.data.mhps.total + "]";
        response.data.mhps.data.forEach((data_point) => {
          data_point.category_type = response.data.dashboard_category;
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
        });

        this.$store.commit("PUSH_TO_MHP", response.data.mhps.data);
        //Referrals
        // this.missed_hot_pocketPtotal = response.data.mhps.last_page;
        this.referralPtotal = response.data.referrals.last_page;
        document
          .getElementById("nonM7referralstab")
          .getElementsByTagName("span")[0].innerText =
          "Referrals [" + response.data.referrals.total + "]";
        response.data.referrals.data.forEach((data_point) => {
          data_point.category_type = response.data.dashboard_category;
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
        });
        this.$store.commit("PUSH_TO_REFERRALS", response.data.referrals.data);
        // this.Untapped = response.data.untapped.data;
        this.missedcallPtotal = response.data.missed_calls.last_page;
        document
          .getElementById("MissedcallsTab")
          .getElementsByTagName("span")[0].innerText =
          "Missed Calls [" + response.data.missed_calls.total + "]";
        let sendarr = [];
        response.data.missed_calls.data.forEach((data_point) => {
          // console.log(data_point);
          data_point.category_type = "missed_calls";
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
          sendarr.push(data_point.call_data);
          // if (
          //   Object.keys(data_point.mwb).length === 0 &&
          //   data_point.mwb.constructor === Object
          // ) {
          //   // console.log("this lead is untracked missed call");
          //   data_point.mwb = data_point.call_data;
          //   data_point.mwb.category_type = "missed_calls";
          //   data_point.mwb.mobile = [];
          //   data_point.mwb.emails = [];
          //   data_point.mwb.engagements = [];
          //   sendarr.push(data_point.mwb);
          // } else {
          //   data_point.mwb.category_type = "missed_calls";
          //   data_point.mwb.mobile = [];
          //   data_point.mwb.emails = [];
          //   data_point.mwb.engagements = [];
          //   data_point.mwb.phone_number = data_point.call_data.phone_number;
          //   data_point.mwb.time = data_point.call_data.time;
          //   sendarr.push(data_point.mwb);
          // }
          // data_point.mwb.contact_type =
          // response.data.dashboard_data.call_data.contact_type;
        });
        this.$store.commit(
          "PUSH_TO_MISSED_CALLS",
          // response.data.dashboard_data
          sendarr
        );
        this.MissedCalls = sendarr;

        this.todayPtotal = response.data.today.last_page;
        document
          .getElementById("nonM7todayTab")
          .getElementsByTagName("span")[0].innerText =
          "Today [" + response.data.today.total + "]";
        response.data.today.data.forEach((data_point) => {
          data_point.category_type = "today";
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
        });
        // console.log("today ", response);
        this.$store.commit("PUSH_TO_TODAY", response.data.today.data);
        this.Today = response.data.today.data;

        this.waitingPtotal = response.data.waiting.last_page;
        document
          .getElementById("nonM7waitingTab")
          .getElementsByTagName("span")[0].innerText =
          "Waiting [" + response.data.waiting.total + "]";
        response.data.waiting.data.forEach((data_point) => {
          data_point.category_type = "waiting";
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
        });
        // console.log("waiting", response);
        this.$store.commit("PUSH_TO_WAITING", response.data.waiting.data);
        this.Waiting = response.data.waiting.data;

        this.delayPtotal = response.data.delays.last_page;
        document
          .getElementById("nonM7delaysTab")
          .getElementsByTagName("span")[0].innerText =
          "Delays [" + response.data.delays.total + "]";
        response.data.delays.data.forEach((data_point) => {
          data_point.category_type = "delays";
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
        });
        // console.log("delays", response);
        this.$store.commit("PUSH_TO_DELAYS", response.data.delays.data);
        this.Delays = response.data.delays.data;

        document
          .getElementById("ToupdateTab")
          .getElementsByTagName("span")[0].innerText =
          "To Update [" + response.data.to_update.length + "]";
        // console.log("getupdate", response);
        response.data.to_update.forEach((data_point) => {
          data_point.category_type = "to_update";
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
        });
        this.$store.commit("PUSH_TO_UPDATE", response.data.to_update);
        this.ToUpdate = response.data.to_update;

        this.untrackedPtotal = response.data.untracked.last_page;
        document
          .getElementById("UntrackedTab")
          .getElementsByTagName("span")[0].innerText =
          " Untracked [" + response.data.untracked.total + "]";
        response.data.untracked.data.forEach((data_point) => {
          data_point.category_type = "untracked";
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
        });
        this.$store.commit("PUSH_TO_UNTRACKED", response.data.untracked.data);
        this.Untracked = response.data.untracked.data;

        document
          .getElementById("CalllogsTab")
          .getElementsByTagName("span")[0].innerText =
          " Call Logs [" + response.data.call_logs.length + "]";
        response.data.call_logs.forEach((data_point) => {
          data_point.category_type = "call_logs";
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
        });
        this.$store.commit("PUSH_TO_CALL_LOG", response.data.call_logs);
        this.CallLog = response.data.call_logs;
        // net enquiries
        // this.netEnquiriesPtotal = response.data.net_enquiries.last_page;
        // document
        //     .getElementById("NetEnquiriesTab")
        //     .getElementsByTagName("span")[0].innerText =
        //     " Net Enquiries [" + response.data.net_enquiries.total + "]";
        // response.data.net_enquiries.data.forEach((data_point) => {
        //     data_point.category_type = "net_enquiries";
        //     // data_point.mobile = data_point.mobile.split(",");
        //     data_point.dialingNumber = "";
        //     // if (data_point.mobile.length > 0) {
        //     //   data_point.dialingNumber = this.getLastTenDigits(
        //     //     data_point.mobile[0]
        //     //   );
        //     // }
        //     if (data_point.mobile !== null && data_point.mobile !== "") {
        //     data_point.dialingNumber = this.getLastTenDigits(
        //         data_point.mobile
        //     );
        //     }
        //     data_point.email = data_point.email.split(",");
        //     data_point.Emails = "";
        //     if (data_point.email.length > 0) {
        //     data_point.Emails = data_point.email[0];
        //     }
        //     data_point.engagements = [];
        // });
        // if (this.$route.path !== "/queue") {
        //   this.$store.commit(
        //     "PUSH_TO_NET_ENQUIRIES",
        //     response.data.net_enquiries.data
        //   );
        //   this.NetEnquiries = response.data.net_enquiries.data;
        // }
        if (this.callPagination) {
          this.decidePagination();
          this.calldecidePagination();
        }
        this.missedcallpagination = true;
      }
      this.$vs.loading.close();
    },
    M7assignTostore(response) {
      console.log("M&assing", response);
      if (
        this.LevelsSelect === "Onboarding" ||
        this.LevelsSelect === "SR-M7" ||
        this.LevelsSelect === "SR-M8,M9,M10"
      ) {
        this.escalations = response.data.escalations;
        // this.escalationText = `Efcalations [${response.data.escalations.length}]`;
        // console.log(this.escalationText);
        document
          .getElementById("M7EscalationsTab")
          .getElementsByTagName("span")[0].innerText =
          "Escalations [" + response.data.escalations.length + "]";
        console.log(this.escalations);
        // this.untappedPtotal = response.data.untapped.last_page;

        // response.data.untapped.data.forEach((data_point) => {
        //   console.log("untapped", data_point);
        // });
        // this.$store.commit("PUSH_TO_UNTAPPED", response.data.untapped.data);
        // this.Untapped = response.data.untapped.data;

        // this.flagPtotal = response.data.flag.last_page;
        // this.flagLabel = `Flag [${response.data.flag.total}]`;
        // document
        //     .getElementById("FlagTab")
        //     .getElementsByTagName("span")[0].innerText =
        //     "Flag [" + response.data.flag.total + "]";
        this.flagSection = response.data.flag.data;
        this.missed_hot_pocketPtotal = response.data.mhps.last_page;
        document
          .getElementById("M7mhptab")
          .getElementsByTagName("span")[0].innerText =
          "MHP [" + response.data.mhps.total + "]";
        response.data.mhps.data.forEach((data_point) => {
          data_point.category_type = response.data.dashboard_category;
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
        });
        this.$store.commit("PUSH_TO_MHP", response.data.mhps.data);
        this.referralPtotal = response.data.referrals.last_page;
        document
          .getElementById("M7referralstab")
          .getElementsByTagName("span")[0].innerText =
          "Referrals [" + response.data.referrals.total + "]";
        response.data.referrals.data.forEach((data_point) => {
          data_point.category_type = response.data.dashboard_category;
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
        });
        this.$store.commit("PUSH_TO_REFERRALS", response.data.referrals.data);
        // this.Untapped = response.data.untapped.data;
        this.missedcallPtotal = response.data.missed_calls.last_page;
        document
          .getElementById("MissedcallsTab")
          .getElementsByTagName("span")[0].innerText =
          "Missed Calls [" + response.data.missed_calls.total + "]";
        let sendarr = [];
        response.data.missed_calls.data.forEach((data_point) => {
          // console.log(data_point);
          data_point.category_type = "missed_calls";
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
          sendarr.push(data_point.call_data);
        });
        this.$store.commit(
          "PUSH_TO_MISSED_CALLS",
          // response.data.dashboard_data
          sendarr
        );
        this.MissedCalls = sendarr;
        this.todayPtotal = response.data.today.last_page;
        document
          .getElementById("M7todayTab")
          .getElementsByTagName("span")[0].innerText =
          "Today [" + response.data.today.total + "]";
        response.data.today.data.forEach((data_point) => {
          data_point.category_type = "today";
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
        });
        // console.log("today ", response);
        this.$store.commit("PUSH_TO_TODAY", response.data.today.data);
        this.Today = response.data.today.data;
        this.waitingPtotal = response.data.waiting.last_page;
        document
          .getElementById("M7waitingTab")
          .getElementsByTagName("span")[0].innerText =
          "Waiting [" + response.data.waiting.total + "]";
        response.data.waiting.data.forEach((data_point) => {
          data_point.category_type = "waiting";
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
        });
        // console.log("waiting", response);
        this.$store.commit("PUSH_TO_WAITING", response.data.waiting.data);
        this.Waiting = response.data.waiting.data;
        this.delayPtotal = response.data.delays.last_page;
        document
          .getElementById("M7delaysTab")
          .getElementsByTagName("span")[0].innerText =
          "Delays [" + response.data.delays.total + "]";
        response.data.delays.data.forEach((data_point) => {
          data_point.category_type = "delays";
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
        });
        // console.log("delays", response);
        this.$store.commit("PUSH_TO_DELAYS", response.data.delays.data);
        this.Delays = response.data.delays.data;
        document
          .getElementById("ToupdateTab")
          .getElementsByTagName("span")[0].innerText =
          "To Update [" + response.data.to_update.length + "]";
        // console.log("getupdate", response);
        response.data.to_update.forEach((data_point) => {
          data_point.category_type = "to_update";
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
        });
        this.$store.commit("PUSH_TO_UPDATE", response.data.to_update);
        this.ToUpdate = response.data.to_update;
        this.untrackedPtotal = response.data.untracked.last_page;
        document
          .getElementById("UntrackedTab")
          .getElementsByTagName("span")[0].innerText =
          " Untracked [" + response.data.untracked.total + "]";
        response.data.untracked.data.forEach((data_point) => {
          data_point.category_type = "untracked";
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
        });
        this.$store.commit("PUSH_TO_UNTRACKED", response.data.untracked.data);
        this.Untracked = response.data.untracked.data;
        document
          .getElementById("CalllogsTab")
          .getElementsByTagName("span")[0].innerText =
          " Call Logs [" + response.data.call_logs.length + "]";
        response.data.call_logs.forEach((data_point) => {
          data_point.category_type = "call_logs";
          data_point.mobile = [];
          data_point.emails = [];
          data_point.engagements = [];
        });
        this.$store.commit("PUSH_TO_CALL_LOG", response.data.call_logs);
        this.CallLog = response.data.call_logs;
        // net enquiries
        // this.netEnquiriesPtotal = response.data.net_enquiries.last_page;
        // document
        //     .getElementById("NetEnquiriesTab")
        //     .getElementsByTagName("span")[0].innerText =
        //     " Net Enquiries [" + response.data.net_enquiries.total + "]";
        // response.data.net_enquiries.data.forEach((data_point) => {
        //     data_point.category_type = "net_enquiries";
        //     // data_point.mobile = data_point.mobile.split(",");
        //     data_point.dialingNumber = "";
        //     // if (data_point.mobile.length > 0) {
        //     //   data_point.dialingNumber = this.getLastTenDigits(
        //     //     data_point.mobile[0]
        //     //   );
        //     // }
        //     if (data_point.mobile !== null && data_point.mobile !== "") {
        //     data_point.dialingNumber = this.getLastTenDigits(
        //         data_point.mobile
        //     );
        //     }
        //     data_point.email = data_point.email.split(",");
        //     data_point.Emails = "";
        //     if (data_point.email.length > 0) {
        //     data_point.Emails = data_point.email[0];
        //     }
        //     data_point.engagements = [];
        // });
        // if (this.$route.path !== "/queue") {
        //     this.$store.commit("PUSH_TO_NET_ENQUIRIES", response.data.net_enquiries.data);
        //     // this.NetEnquiries = response.data.net_enquiries.data;
        // }
        // if (this.$route.path !== "/queue") {
        //   this.$store.commit(
        //     "PUSH_TO_NET_ENQUIRIES",
        //     response.data.net_enquiries.data
        //   );
        //   this.NetEnquiries = response.data.net_enquiries.data;
        // }
        if (this.callPagination) {
          this.M7decidePagination();
          this.nonM7decidePagination();
          this.calldecidePagination();
        }
        this.missedcallpagination = true;
      }

      this.$vs.loading.close();
    },
    sortDisplay(missedCalls) {
      missedCalls.forEach((call) => {
        call.times = 1;
        call.previoustime = "";
        this.MissedCalls.push(call);
      });
      for (let y = 0; y < this.MissedCalls.length; y++) {
        // let currentindex = y
        let nextIndex = y + 1;
        let current = this.MissedCalls[y];
        let next = this.MissedCalls[y + 1];
        if (next !== undefined) {
          if (current.candidate_id === next.candidate_id) {
            current.times = current.times + 1;
            current.previoustime = next.time;
            this.MissedCalls.splice(nextIndex, 1);
          }
        }
      }
      this.missedcallslength = this.MissedCalls.length;
    },
    fetch_initital_logs() {
      axios
        .get(`${constants.SERVER_API}getUntapped`, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          // console.log(response);
          this.untappedPtotal = response.data.dashboard_data.last_page;
          // document
          //   .getElementById("untappedTab")
          //   .getElementsByTagName("span")[0].innerText =
          //   "Untapped [" + response.data.dashboard_data.total + "]";
          response.data.dashboard_data.data.forEach((data_point) => {
            data_point.category_type = response.data.dashboard_category;
            data_point.mobile = [];
            data_point.emails = [];
            data_point.engagements = [];
          });
          this.$store.commit(
            "PUSH_TO_UNTAPPED",
            response.data.dashboard_data.data
          );
          // this.Untapped = response.data.dashboard_data.data;
        })
        .catch((error) => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
        });
      axios
        .get(`${constants.SERVER_API}getMissedCalls`, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("missed ", response);
          document
            .getElementById("MissedcallsTab")
            .getElementsByTagName("span")[0].innerText =
            "Missed Calls [" + response.data.dashboard_data.length + "]";
          let sendarr = [];
          response.data.dashboard_data.forEach((data_point) => {
            // console.log(data_point);
            if (
              Object.keys(data_point.mwb).length === 0 &&
              data_point.mwb.constructor === Object
            ) {
              // console.log("this lead is untracked missed call");
              data_point.mwb = data_point.call_data;
              data_point.mwb.category_type = response.data.dashboard_category;
              data_point.mwb.mobile = [];
              data_point.mwb.emails = [];
              data_point.mwb.engagements = [];
              sendarr.push(data_point.mwb);
            } else {
              data_point.mwb.category_type = response.data.dashboard_category;
              data_point.mwb.mobile = [];
              data_point.mwb.emails = [];
              data_point.mwb.engagements = [];
              data_point.mwb.phone_number = data_point.call_data.phone_number;
              data_point.mwb.time = data_point.call_data.time;
              sendarr.push(data_point.mwb);
            }
            // data_point.mwb.contact_type =
            // response.data.dashboard_data.call_data.contact_type;
          });
          this.$store.commit(
            "PUSH_TO_MISSED_CALLS",
            // response.data.dashboard_data
            sendarr
          );
          this.MissedCalls = response.data.dashboard_data;
          // this.$store.commit("SORT_LEADS", "missed");
          this.fetch_remaining();
          // this.findDuplicates("missed_calls");
          // this.$store.commit("SORT_MISSED_CALLS");
          // EventBus.$emit("get-sorting-missed");
        })
        .catch((error) => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
        });
    },
    async fetch_remaining() {
      this.fetch_call_logs();
      axios
        .get(`${constants.SERVER_API}getToday`, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          this.todayPtotal = response.data.dashboard_data.last_page;
          document
            .getElementById("todayTab")
            .getElementsByTagName("span")[0].innerText =
            "Today [" + response.data.dashboard_data.total + "]";
          response.data.dashboard_data.data.forEach((data_point) => {
            data_point.category_type = response.data.dashboard_category;
            data_point.mobile = [];
            data_point.emails = [];
            data_point.engagements = [];
          });
          // console.log("today ", response);
          this.$store.commit(
            "PUSH_TO_TODAY",
            response.data.dashboard_data.data
          );
          this.Today = response.data.dashboard_data.data;
        })
        .catch((error) => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
        });

      axios
        .get(`${constants.SERVER_API}getWaiting`, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          this.waitingPtotal = response.data.dashboard_data.last_page;
          document
            .getElementById("waitingTab")
            .getElementsByTagName("span")[0].innerText =
            "Waiting [" + response.data.dashboard_data.total + "]";
          response.data.dashboard_data.data.forEach((data_point) => {
            data_point.category_type = response.data.dashboard_category;
            data_point.mobile = [];
            data_point.emails = [];
            data_point.engagements = [];
          });
          // console.log("waiting", response);
          this.$store.commit(
            "PUSH_TO_WAITING",
            response.data.dashboard_data.data
          );
          this.Waiting = response.data.dashboard_data.data;
        })
        .catch((error) => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
        });
      axios
        .get(`${constants.SERVER_API}getDelays`, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          // console.log("response ,", response);
          this.delayPtotal = response.data.dashboard_data.last_page;
          document
            .getElementById("delaysTab")
            .getElementsByTagName("span")[0].innerText =
            "Delays [" + response.data.dashboard_data.total + "]";
          response.data.dashboard_data.data.forEach((data_point) => {
            data_point.category_type = response.data.dashboard_category;
            data_point.mobile = [];
            data_point.emails = [];
            data_point.engagements = [];
          });
          // console.log("delays", response);
          this.$store.commit(
            "PUSH_TO_DELAYS",
            response.data.dashboard_data.data
          );
          this.Delays = response.data.dashboard_data.data;
        })
        .catch((error) => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
        });
    },
    async fetch_call_logs() {
      axios
        .get(`${constants.SERVER_API}getToUpdate`, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          // this.updatePtotal = response.data.dashboard_data;
          document
            .getElementById("ToupdateTab")
            .getElementsByTagName("span")[0].innerText =
            "To Update [" + response.data.dashboard_data.length + "]";
          // console.log("getupdate", response);
          response.data.dashboard_data.forEach((data_point) => {
            data_point.category_type = response.data.dashboard_category;
            data_point.mobile = [];
            data_point.emails = [];
            data_point.engagements = [];
          });
          this.$store.commit("PUSH_TO_UPDATE", response.data.dashboard_data);
          this.ToUpdate = response.data.dashboard_data;
          // this.findDuplicates("missed_calls");
        })
        .catch((error) => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
        });

      axios
        .get(`${constants.SERVER_API}getUntrackedCalls`, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          // console.log("untracked ", response);
          // this.untrackedPtotal = response.data.dashboard_data;
          document
            .getElementById("UntrackedTab")
            .getElementsByTagName("span")[0].innerText =
            " Untracked [" + response.data.dashboard_data.length + "]";
          response.data.dashboard_data.forEach((data_point) => {
            data_point.category_type = response.data.dashboard_category;
            data_point.mobile = [];
            data_point.emails = [];
            data_point.engagements = [];
          });
          this.$store.commit("PUSH_TO_UNTRACKED", response.data.dashboard_data);
          this.Untracked = response.data.dashboard_data;
        })
        .catch((error) => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
        });
      axios
        .get(`${constants.SERVER_API}getCallLogs`, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          // console.log("call logs", response);
          // this.calllogPtotal = response.data.dashboard_data;
          document
            .getElementById("CalllogsTab")
            .getElementsByTagName("span")[0].innerText =
            " Call Logs [" + response.data.dashboard_data.length + "]";
          response.data.dashboard_data.forEach((data_point) => {
            data_point.category_type = response.data.dashboard_category;
            data_point.mobile = [];
            data_point.emails = [];
            data_point.engagements = [];
          });
          this.$store.commit("PUSH_TO_CALL_LOG", response.data.dashboard_data);
          this.CallLog = response.data.dashboard_data;
        })
        .catch((error) => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
        });
    },
    getIvrCallLogs() {
      this.ivrTodayCallLogsdata = [];
      this.ivrAllMissedCallsdata = [];
      let user_id = localStorage.getItem("spoc_id");
      let obj = {
        spoc_id: user_id,
      };
      axios
        .get(`${constants.SERVER_API}getIVRCalls`, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("IVRTodayCallLogsTab", response);
          // this.ivrTodayCallLogsdata = response.data.today_calls;
          this.ivrTodayCallLogsdata = [
            ...response.data.answered_calls
              .reduce((mp, o) => {
                if (!mp.has(o.phone_number))
                  mp.set(o.phone_number, { ...o, count: 0 });
                mp.get(o.phone_number).count++;
                return mp;
              }, new Map())
              .values(),
          ];
          this.ivrAllMissedCallsdata = [
            ...response.data.missed_calls
              .reduce((mp, o) => {
                if (!mp.has(o.phone_number))
                  mp.set(o.phone_number, { ...o, count: 0 });
                mp.get(o.phone_number).count++;
                return mp;
              }, new Map())
              .values(),
          ];
          console.log("ivrAllMissedCallsdata", this.ivrAllMissedCallsdata);
          document
            .getElementById("IVRTodayCallLogsTab")
            .getElementsByTagName("span")[0].innerText =
            " IVR Today Call Logs [" + this.ivrTodayCallLogsdata.length + "]";
          document
            .getElementById("IVRAllMissedCallsTab")
            .getElementsByTagName("span")[0].innerText =
            " IVR Missed/Untracked Calls [" +
            this.ivrAllMissedCallsdata.length +
            "]";
          //       let uniqueMissedCallLogs = this.ivrAllMissedCallsdata.map(e => e['caller_id_number'])
          //  .map((e, i, final) => final.indexOf(e) === i && i).filter(obj=> this.ivrAllMissedCallsdata[obj])
          //  .map(e => this.ivrAllMissedCallsdata[e]);
          //       console.log('uniqueMissedCallLogs',uniqueMissedCallLogs)
          //         let uniqueMissedCallLogs =  [...this.ivrAllMissedCallsdata.reduce( (mp, o) => {
          //     if (!mp.has(o.caller_id_number)) mp.set(o.caller_id_number, { ...o, count: 0 });
          //     mp.get(o.caller_id_number).count++;
          //     return mp;
          // }, new Map).values()];
          // console.log('uniqueMissedCallLogs',uniqueMissedCallLogs)
          // this.calllogPtotal = response.data.dashboard_data;
          // document
          //   .getElementById("IVRTodayCallLogsTab")
          //   .getElementsByTagName("span")[0].innerText =
          //   " Call Logs [" + response.data.dashboard_data.length + "]";
          // response.data.dashboard_data.forEach((data_point) => {
          //   data_point.category_type = response.data.dashboard_category;
          //   data_point.mobile = [];
          //   data_point.emails = [];
          //   data_point.engagements = [];
          // });
          // this.$store.commit("PUSH_TO_CALL_LOG", response.data.dashboard_data);
          // this.CallLog = response.data.dashboard_data;
        })
        .catch((error) => {
          // if (error.response) {
          //   console.log("error", error.response.status);
          //   if (error.response.status === 401) {
          //     this.ReDirect_to_login();
          //   }
          // }
          this.handleError(error);
        });
    },
  },
};
</script>
<style>
.headr {
  color: gray;
  font-size: 25px;
  margin-bottom: 2%;
}
.gap {
  margin-bottom: 4%;
}
.inicolor {
  color: gray;
}
.cardtext {
  color: gray;
  font-size: 20px;
}
</style>
